import styled from "@emotion/styled";
import { query, collection, where, getDocs } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SectionHeader from "../../components/SectionHeader";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend, PointElement, LineElement } from "chart.js";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { es } from "date-fns/locale";
import { Chip } from "@mui/material";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend, PointElement, LineElement);

const Container = styled.div`
  background-color: #f6f6f6;
  position: relative;
  padding-bottom: 150px;
`;

type IAccess = {
  id: string;
  creationDate: number;
  totalAccess: { userId: string; timestamp: number }[];
};

const DashboardContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  padding: 24px;
`;

const FullWidthCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  grid-column: span 2;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
`;

const TitleHTML = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 8px;
`;

const StatValue = styled.p`
  font-size: 2rem;
  font-weight: 600;
`;

const Statistics = () => {
  const { db } = useContext(FirebaseContext);
  const currentYear = new Date().getFullYear();

  const [selectedPeriod, setSelectedPeriod] = useState("Últimos 7 días");
  const [selectedYears, setSelectedYears] = useState<number[]>([2023,2024,2025]);
  const [customStartDate, setCustomStartDate] = useState<Date | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Date | null>(null);
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = useState(false);
  const [monthlyAccessByYear, setMonthlyAccessByYear] = useState<Record<number, number[]>>({});

  // Estados donde guardamos los datos traídos de Firestore
  const [accessData, setAccessData] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  // const [eventData, setEventData] = useState([]);

  // Estados para filtrar por empresas y usuarios
  const [filterType, setFilterType] = useState(""); // "empresa" o "usuario"
  const [selectedFilter, setSelectedFilter] = useState(""); // ID de empresa o usuario seleccionado
  const [businessList, setBusinessList] = useState<{ id: string; name: string }[]>([]);
  const [userList, setUserList] = useState<{ id: string; name: string }[]>([]);

  const toggleYear = (year: number) => {
    setSelectedYears((prev) => (prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]));
  };

  useEffect(() => {
    const fetchMonthlyAccess = async () => {
      const now = new Date();
      const startYear = now.getFullYear() - 2;
      const startDate = new Date(startYear, 0, 1);

      const rawData: Record<number, number[]> = {
        [startYear]: Array(12).fill(0),
        [startYear + 1]: Array(12).fill(0),
        [startYear + 2]: Array(12).fill(0),
      };

      const accessQuery = query(collection(db, "statistics-access"), where("creationDate", ">=", startDate.getTime()));

      const snapshot = await getDocs(accessQuery);
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        const creationDate = new Date(data.creationDate);
        const year = creationDate.getFullYear();
        const month = creationDate.getMonth();
        const accessCount = data.totalAccess?.length || 0;

        if (rawData[year]) {
          rawData[year][month] += accessCount;
        }
      });

      setMonthlyAccessByYear(rawData);
    };

    fetchMonthlyAccess();
  }, []);

  useEffect(() => {
    fetchStatistics();
  }, [selectedPeriod, customStartDate, customEndDate, selectedFilter]);

  // Función para establecer el rango de fechas en función del botón seleccionado
  const getStartDate = () => {
    const now = new Date();
    let startDate = new Date(now);
    let endDate = new Date(now);

    if (selectedPeriod === "Hoy") {
      // Hoy desde 00:00:00 hasta 23:59:59
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
    } else if (selectedPeriod === "Ayer") {
      // Ayer desde 00:00:00 hasta 23:59:59
      startDate.setDate(now.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);

      endDate.setDate(now.getDate() - 1);
      endDate.setHours(23, 59, 59, 999);
    } else if (selectedPeriod === "Últimos 7 días") {
      startDate.setDate(now.getDate() - 7);
      startDate.setHours(0, 0, 0, 0);

      endDate.setHours(23, 59, 59, 999);
    } else if (selectedPeriod === "Último mes") {
      startDate.setMonth(now.getMonth() - 1);
      startDate.setHours(0, 0, 0, 0);

      endDate.setHours(23, 59, 59, 999);
    } else if (selectedPeriod === "Último año") {
      startDate.setFullYear(now.getFullYear() - 1);
      startDate.setHours(0, 0, 0, 0);

      endDate.setHours(23, 59, 59, 999);
    }

    return {
      start: startDate.getTime(),
      end: endDate.getTime(),
    };
  };

  // Función para obtener el inicio y fin de las fechas personalizadas
  const getCustomDateRange = () => {
    if (customStartDate && customEndDate) {
      customStartDate.setHours(0, 0, 0, 0);
      customEndDate.setHours(23, 59, 59, 999);
      return { start: customStartDate.getTime(), end: customEndDate.getTime() };
    }
    return null;
  };

  useEffect(() => {
    const fetchBusinesses = async () => {
      const businessSnap = await getDocs(collection(db, "business"));
      const businesses = businessSnap.docs
        .map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Orden alfabético
      setBusinessList(businesses);
    };

    const fetchUsers = async () => {
      const userSnap = await getDocs(collection(db, "users"));
      const users = userSnap.docs
        .map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Orden alfabético
      setUserList(users);
    };

    fetchBusinesses();
    fetchUsers();
  }, []);

  // Modificar fetchStatistics para incluir el rango de fechas personalizadas
  const fetchStatistics = async () => {
    let startDate;
    let endDate;

    if (customStartDate && customEndDate) {
      const range = getCustomDateRange();
      if (range) {
        startDate = range.start;
        endDate = range.end;
      }
    } else {
      const range = getStartDate();
      startDate = range.start;
      endDate = range.end;
    }

    // Definir el filtro según el tipo seleccionado
    let accessQuery = query(collection(db, "statistics-access"), where("creationDate", ">=", startDate), where("creationDate", "<=", endDate));

    // Ejecutar la consulta de accesos
    const accessSnap = await getDocs(accessQuery);
    const accessList = accessSnap.docs.map((doc) => ({
      id: doc.id,
      creationDate: doc.data().creationDate,
      totalAccess: doc.data().totalAccess ? doc.data().totalAccess.length : 0,
    }));
    setAccessData(accessList);

    // Reservas
    let reservationsQuery = query(collection(db, "sala-books"), where("daySelected", ">=", startDate));
    if (filterType === "empresa" && selectedFilter) {
      reservationsQuery = query(reservationsQuery, where("businessId", "==", selectedFilter));
    }

    const reservationsSnap = await getDocs(reservationsQuery);
    setReservationData(reservationsSnap.docs.map((doc) => doc.data()));

    // Tickets
    let ticketsQuery = query(collection(db, "tickets"), where("timestamp", ">=", startDate));
    if (filterType === "empresa" && selectedFilter) {
      ticketsQuery = query(ticketsQuery, where("businessId", "==", selectedFilter));
    }

    const ticketsSnap = await getDocs(ticketsQuery);
    setTicketData(ticketsSnap.docs.map((doc) => doc.data()));

    // Eventos
    // const eventsQuery = query(collection(db, "events-business"), where("initTimestamp", ">=", startDate));
    // const eventsSnap = await getDocs(eventsQuery);
    // setEventData(eventsSnap.docs.map((doc) => doc.data()));
  };

  const getWeeklyAverages = () => {
    const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
    const counts = { Lunes: 0, Martes: 0, Miércoles: 0, Jueves: 0, Viernes: 0, Sábado: 0, Domingo: 0 };
    const totals = { Lunes: 0, Martes: 0, Miércoles: 0, Jueves: 0, Viernes: 0, Sábado: 0, Domingo: 0 };

    accessData.forEach((entry) => {
      const date = new Date(entry.creationDate);
      const day = weekDays[date.getDay() - 1];
      if (day) {
        counts[day]++;
        totals[day] += entry.totalAccess;
      }
    });

    return weekDays.map((day) => (counts[day] > 0 ? totals[day] / counts[day] : 0));
  };

  // Determinar qué mensaje mostrar en el overlay
  const getOverlayMessage = () => {
    if (!customStartDate && !customEndDate) {
      return "Selecciona una fecha de inicio y una fecha fin";
    }
    if (customStartDate && !customEndDate) {
      return "Selecciona una fecha de fin";
    }
    return null; // Si ambas fechas están seleccionadas, no hay overlay
  };

  // Obtener el mensaje actual del overlay
  const overlayMessage = isCustomDatePickerOpen ? getOverlayMessage() : null;

  // Si el overlay tiene un mensaje, lo mostramos
  const showOverlay = Boolean(overlayMessage);

  // ***************** AGRUPAR LAS RESERVAS ÚNICAS *****************
  // Queremos que cada reserva hecha por el mismo businessId, el mismo daySelected
  // y la misma salaId cuente como 1 (aunque tenga 5 horas).
  // Creamos un Map o un objeto para identificar reservas únicas.
  const uniqueReservationsMap = new Map();
  reservationData.forEach((r) => {
    // Podríamos hacer la clave de esta forma:
    const uniqueKey = `${r.businessId}-${r.daySelected}-${r.salaId}`;
    // Si no existe, lo metemos
    if (!uniqueReservationsMap.has(uniqueKey)) {
      uniqueReservationsMap.set(uniqueKey, r);
    }
    // Si existe, ignoramos (para no contar la misma reserva varias veces).
  });

  // De este modo, uniqueReservations ahora contiene solo 1 entrada por reserva,
  // sin duplicar para cada "timeSelected" de la misma reserva.
  const uniqueReservations = Array.from(uniqueReservationsMap.values());

  // Ahora, contamos cuántas reservas hay por cada salaId.
  // Suponiendo que tus salas son: "reuniones", "privadas" y "abiertas".
  const reservasPorSala = {
    VCrxKEI55awpqy4OcFMR: 0,
    mk7nZU9o50uXXUi4F9Tj: 0,
    ucuwSxZHDZk8iB2dhdAe: 0,
  };

  uniqueReservations.forEach((res) => {
    if (res.salaId && reservasPorSala[res.salaId] !== undefined) {
      reservasPorSala[res.salaId]++;
    }
  });
  // Para el Pie chart de “Distribución de reservas” usando los datos reales
  const distributionData = {
    labels: ["Polivalente", "Aula BMC", "Sala de Juntas"],
    datasets: [
      {
        label: "Reservas",
        data: [reservasPorSala.VCrxKEI55awpqy4OcFMR, reservasPorSala.mk7nZU9o50uXXUi4F9Tj, reservasPorSala.ucuwSxZHDZk8iB2dhdAe],
        // Los backgroundColor se pueden quitar o dejar;
        // los pongo para diferenciar cada sector
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const borderColor = filterType === "usuario" && selectedFilter ? "#36A2EB" : filterType === "empresa" && selectedFilter ? "#4CAF50" : "transparent";

  return (
    <Layout>
      <Container>
        <SectionHeader
          src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/statistics%2Fcabecera_estadisticas.png?alt=media&token=794cc90f-c01b-4c0a-8c10-24dbdebc6900"
          title="ESTADÍSTICAS"
          subtitle="Visualiza todos los datos de tu edificio"
          showBackButton
        />
        {/* Filtro de período ocupando el 100% */}
        <FullWidthCard>
          <TitleHTML>Seleccionar período</TitleHTML>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginBottom: "12px" }}>
            <Button
              variant={selectedPeriod === "Hoy" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedPeriod("Hoy");
                setCustomStartDate(null);
                setCustomEndDate(null);
                setIsCustomDatePickerOpen(false); // Desactivar personalizado
              }}
            >
              Hoy
            </Button>
            <Button
              variant={selectedPeriod === "Ayer" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedPeriod("Ayer");
                setCustomStartDate(null);
                setCustomEndDate(null);
                setIsCustomDatePickerOpen(false); // Desactivar personalizado
              }}
            >
              Ayer
            </Button>

            <Button
              variant={selectedPeriod === "Últimos 7 días" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedPeriod("Últimos 7 días");
                setCustomStartDate(null);
                setCustomEndDate(null);
                setIsCustomDatePickerOpen(false);
              }}
            >
              Últimos 7 días
            </Button>

            <Button
              variant={selectedPeriod === "Último mes" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedPeriod("Último mes");
                setCustomStartDate(null);
                setCustomEndDate(null);
                setIsCustomDatePickerOpen(false);
              }}
            >
              Último mes
            </Button>

            <Button
              variant={selectedPeriod === "Último año" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedPeriod("Último año");
                setCustomStartDate(null);
                setCustomEndDate(null);
                setIsCustomDatePickerOpen(false);
              }}
            >
              Último año
            </Button>

            <Button
              variant={isCustomDatePickerOpen ? "contained" : "outlined"}
              onClick={() => {
                setSelectedPeriod(""); // Desactivar los botones
                setIsCustomDatePickerOpen(true);
                setCustomStartDate(null); // Reset fechas
                setCustomEndDate(null);
              }}
            >
              Personalizado
            </Button>
          </div>
          {/* Mostrar el Badge si hay un filtro seleccionado */}
          {selectedFilter && (
            <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
              <Chip
                label={`Filtro activo: ${filterType === "empresa" ? "Empresa" : "Usuario"} - ${
                  filterType === "empresa" ? businessList.find((b) => b.id === selectedFilter)?.name : userList.find((u) => u.id === selectedFilter)?.name
                }`}
                color="primary"
                onDelete={() => {
                  setSelectedFilter("");
                  setFilterType("");
                }}
                style={{ fontSize: "14px", padding: "5px 10px" }}
              />
            </div>
          )}
          {isCustomDatePickerOpen && (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <DatePicker label="Fecha inicio" value={customStartDate} onChange={(newValue) => setCustomStartDate(newValue)} renderInput={(params) => <TextField {...params} />} />
                <DatePicker label="Fecha fin" value={customEndDate} onChange={(newValue) => setCustomEndDate(newValue)} renderInput={(params) => <TextField {...params} />} />
              </div>
            </LocalizationProvider>
          )}
        </FullWidthCard>
        <DashboardContainer>
          {showOverlay && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10, // Asegura que el overlay esté encima del contenido
                borderRadius: "8px", // Para respetar el diseño del Card
              }}
            >
              <CircularProgress color="primary" />
              <Typography variant="h5" style={{ marginTop: "20px", color: "white" }}>
                {overlayMessage}
              </Typography>
            </div>
          )}
          <Card style={{ border: `2px solid ${borderColor}` }}>
            <TitleHTML>Accesos al edificio</TitleHTML>
            <StatValue>{accessData.reduce((sum, entry) => sum + entry.totalAccess, 0)}</StatValue>
          </Card>
          <Card>
            <TitleHTML>Media de accesos al edificio</TitleHTML>
            <StatValue>{(accessData.reduce((sum, entry) => sum + entry.totalAccess, 0) / accessData.length).toFixed(2)}</StatValue>
          </Card>

          <Card>
            <TitleHTML>Reservas realizadas</TitleHTML>
            {/* Usamos el length de uniqueReservations, ya que es el conteo real */}
            <StatValue>{uniqueReservations.length}</StatValue>
          </Card>

          <Card>
            <TitleHTML>Tickets totales</TitleHTML>
            <StatValue>{ticketData.length}</StatValue>
          </Card>
          <Card>
            <TitleHTML>Accesos promedio por día de la semana</TitleHTML>
            <Bar
              data={{
                labels: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
                datasets: [
                  {
                    label: "Promedio de Accesos",
                    data: getWeeklyAverages(),
                    backgroundColor: "#36A2EB",
                  },
                ],
              }}
            />
          </Card>
          <FullWidthCard>
            <TitleHTML>Accesos al edificio</TitleHTML>
            <Bar
              data={{
                // Mostramos los 30 últimos docs
                labels: accessData.slice(-30).map((entry) => entry.id),
                datasets: [
                  {
                    label: "Accesos",
                    data: accessData.slice(-30).map((entry) => entry.totalAccess),
                    backgroundColor: "#36A2EB",
                  },
                ],
              }}
            />
          </FullWidthCard>
          <FullWidthCard>
            <TitleHTML>Accesos por mes (últimos 3 años)</TitleHTML>

            {/* Checkboxes de los años */}
            <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
              {[currentYear - 2, currentYear - 1, currentYear].map((year) => (
                <label key={year} style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <input type="checkbox" checked={selectedYears.includes(year)} onChange={() => toggleYear(year)} />
                  <span>{year}</span>
                </label>
              ))}
            </div>

            <Line
              data={{
                labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                datasets: selectedYears.map((year) => ({
                  label: `${year}`,
                  data: monthlyAccessByYear[year] || [],
                  borderColor: year === currentYear ? "#36A2EB" : year === currentYear - 1 ? "#FF6384" : "#4BC0C0",
                  backgroundColor: "transparent",
                })),
              }}
            />
          </FullWidthCard>
          <Card>
            <TitleHTML>Distribución de reservas</TitleHTML>
            {/* Pie chart con datos agrupados por sala */}
            <Pie data={distributionData} />
          </Card>
          <Card>
            <TitleHTML>Tickets de mantenimiento por estado</TitleHTML>
            <Bar
              data={{
                labels: ["Pendientes", "En progreso", "Cerrados"],
                datasets: [
                  {
                    label: "Tickets",
                    data: [ticketData.filter((t) => t.status === "pending").length, ticketData.filter((t) => t.status === "progress").length, ticketData.filter((t) => t.status === "closed").length],
                    backgroundColor: "#36A2EB",
                  },
                ],
              }}
            />
          </Card>
        </DashboardContainer>
      </Container>
    </Layout>
  );
};

export default Statistics;
