const Step2 = ({ width, height }: {width?:string, height?:string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "27.552"}
      height={height ? height : "27.552"}
      viewBox="0 0 27.552 27.552"
    >
      <path
        id="paso2"
        d="M4,9.958V4H9.958V6.066H6.066V9.958ZM4,31.552V25.594H6.066v3.892H9.958v2.066Zm21.594,0V29.485h3.892V25.594h2.066v5.958ZM29.485,9.958V6.066H25.594V4h5.958V9.958Zm-3.857,15.7H27.8v2.17h-2.17Zm0-4.339H27.8v2.17h-2.17Zm-2.17,2.17h2.17v2.17h-2.17Zm-2.17,2.17h2.17v2.17h-2.17Zm-2.17-2.17h2.17v2.17h-2.17Zm4.339-4.339h2.17v2.17h-2.17Zm-2.17,2.17h2.17v2.17h-2.17Zm-2.17-2.17h2.17v2.17h-2.17ZM27.8,7.719V16.4H19.119V7.719ZM16.4,19.153v8.679H7.719V19.153Zm0-11.434V16.4H7.719V7.719ZM14.676,26.11V20.875H9.441V26.11Zm0-11.434V9.441H9.441v5.235Zm11.4,0V9.441H20.841v5.235Z"
        transform="translate(-4 -4)"
        fill="#fff"
      />
    </svg>
  );
};

export default Step2;
