import { useContext, useRef, useState } from "react";
import Layout from "../../components/Layout";
import { QrReader } from "react-qr-reader";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../fb/config";
import SectionHeader from "../../components/SectionHeader";
import styled from "@emotion/styled";
import ModalCusstom from "../../components/ModalCustom";
import { useNavigate } from "react-router";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { FirebaseContext } from "../../context/FirebaseContext";
import Loading from "../../components/Loading";
import { GYM, SALAS, TORNOS } from "../../utils/constants";
import { checkTypeQr } from "../../utils/functions";
import { SalasContext } from "../../context/SalasContext";
import { Title } from "../../genericComponents";

const ContainerTurnsButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  gap: 10px;
  box-sizing: border-box;
`;

const ButtonTurn = styled.button`
  flex-grow: 1; /* Hace que cada botón crezca de manera uniforme */
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 25px;
  font-weight: 200;
  margin: 0px;
  padding: 10px; /* Ajuste opcional para mejor apariencia */
  min-width: 80px; /* Evita que los botones sean demasiado pequeños */
  height: 80px;
  text-align: center;
  color: black;
`;

const ContainerDoorButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 120px 20px;
`;

const ButtonDoor = styled.button`
  width: 100%;
  height: 80px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  font-size: 25px;
  font-weight: 200;
  margin: 10px 0px;
  padding-right: 15px;
  color: black;
`;

const QrScanner = () => {
  const [data, setData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { db, user } = useContext(FirebaseContext);
  const { checkOpenDoor } = useContext(SalasContext);
  const navigation = useNavigate();
  const openTorn = httpsCallable(functions, "openTorn");
  const scanning = useRef(false);

  const newEntry = async () => {
    let date = new Date();
    let idToday = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const docRef = doc(db, "statistics-access", idToday);
    const docSnap = await getDoc(docRef);

    const userEntry = { userId: user.id, timestamp: new Date().getTime(), businessId: user.businessId };

    if (docSnap.exists()) {
      let result = docSnap.data();
      if (result?.totalAccess?.some((res) => res.userId === user.id)) return;
      await updateDoc(docRef, { totalAccess: arrayUnion(userEntry) });
    } else {
      await setDoc(docRef, {
        creationDate: new Date().setHours(0, 0, 0, 0),
        totalAccess: [userEntry],
      });
    }
  };

  const openDoor = async (result) => {
    console.log(result);
    try {
      if (!result) return;

      setLoading(true);
      let typeQr = checkTypeQr(result);
      let message = "No tienes permiso para acceder.";

      if (typeQr === TORNOS || typeQr === GYM || user.roles.super || user.roles.cleaner) {
        await openTorn({ nameTorno: result });
        message = "Puerta abierta correctamente";
      } else if (typeQr === SALAS) {
        let resultToCheck = await checkOpenDoor(result);
        if (resultToCheck) {
          await openTorn({ nameTorno: result });
          message = `Bienvenido ${resultToCheck.userData.name}`;
        }
      }

      setOpenModal(true);
      setData(message);
      await newEntry();

      setTimeout(
        () => {
          setOpenModal(false);
          navigation("/");
        },
        message.includes("acceder") ? 3000 : 2000
      );
    } catch (error) {
      setOpenModal(true);
      setData("Código erróneo. Inténtelo de nuevo");
      setTimeout(() => setOpenModal(false), 2000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SectionHeader title="ACCESO" subtitle="Selecciona la puerta para abrir." showBackButton />
      <Title color="black" top="15px" left="15px" weight="200" size="30px">
        Tornos
      </Title>
      <ContainerTurnsButtons>
        {[1, 2, 3, 4].map((item) => (
          <ButtonTurn onClick={() => openDoor(`torno${item}`)}>{item}</ButtonTurn>
        ))}
      </ContainerTurnsButtons>
      <Title color="black" top="15px" left="15px" weight="200" size="30px">
        Salas
      </Title>
      <ContainerDoorButtons>
        <ButtonDoor onClick={() => openDoor("juntas")}>Juntas</ButtonDoor>
        <ButtonDoor onClick={() => openDoor("aula")}>Aula</ButtonDoor>
        <ButtonDoor onClick={() => openDoor("polivalente")}>Polivalente</ButtonDoor>
        <ButtonDoor onClick={() => openDoor("gym")}>Gym</ButtonDoor>
        <ModalCusstom message={data} open={openModal} />
      </ContainerDoorButtons>
      {loading && <Loading />}
    </Layout>
  );
};

export default QrScanner;
