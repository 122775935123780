const Step1 = ({width, height}: {width?:string, height?:string}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width? width : "22.104"}
      height={height ? height : "34.734"}
      viewBox="0 0 22.104 34.734"
    >
      <path
        id="paso1"
        d="M12.368,36.734A2.358,2.358,0,0,1,10,34.366v-30a2.284,2.284,0,0,1,.691-1.677A2.284,2.284,0,0,1,12.368,2H29.735a2.284,2.284,0,0,1,1.677.691A2.284,2.284,0,0,1,32.1,4.368v30a2.358,2.358,0,0,1-2.368,2.368Zm0-3.552v1.184H29.735V33.182Zm0-27.629H29.735V4.368H12.368Zm8.684,20.919a14.87,14.87,0,0,0-4.579.71,19.363,19.363,0,0,0-4.1,1.855v1.776H29.735V29.037a19.363,19.363,0,0,0-4.1-1.855A14.87,14.87,0,0,0,21.052,26.472Zm0-2.368a16.869,16.869,0,0,1,4.559.612,19.234,19.234,0,0,1,4.125,1.678V7.921H12.368V26.393a20.262,20.262,0,0,1,4.144-1.678A16.655,16.655,0,0,1,21.052,24.1Zm.039-2.487a4.582,4.582,0,0,0,4.579-4.579,4.582,4.582,0,0,0-4.579-4.579,4.582,4.582,0,0,0-4.579,4.579,4.582,4.582,0,0,0,4.579,4.579Zm0-2.368A2.224,2.224,0,1,1,22.65,18.6,2.127,2.127,0,0,1,21.091,19.249Zm-.039,11.565h0Zm.039-13.775ZM21.052,5.552Zm0,27.629Z"
        transform="translate(-10 -2)"
        fill="#fff"
      />
    </svg>
  );
};

export default Step1;
