import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { Text, Title } from "../../genericComponents";
import LazyImage from "../LazyImage";

const SectionHeaderContainer = styled.div`
  background-color: #8B7D6A;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
`;

const TextContainerHeader = styled.div`
  padding: 20px;
`;


const SectionHeader = ({
  title,
  subtitle,
  src,
  showBackButton,
}: {
  title: string;
  subtitle?: string;
  src?: string;
  showBackButton?: boolean;
}) => {
  let navigate = useNavigate();

  return (
    <SectionHeaderContainer>
      {src && (
        <LazyImage
          animation="wave"
          variant="rectangular"
          width="100%"
          height="91px"
          src={src}
        />
      )}
      {showBackButton && (
        <Text
          left="20px"
          top="0"
          weight="100"
          size="24px"
          onClick={() => navigate(-1)}
          color="white"
        >
          ←
        </Text>
      )}
      <TextContainerHeader>
        <Title color="white" top="0" weight="200" size="25px">
          {title}
        </Title>
        {subtitle && (
          <Text color="white" top="10px" weight="200" size="14px">
            {subtitle}
          </Text>
        )}
      </TextContainerHeader>
    </SectionHeaderContainer>
  );
};

export default SectionHeader;
